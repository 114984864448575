import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import IntroBlock from "../../components/Category/IntroBlock";
import Seo from "../../components/Core/Seo";
import Layout from "../../components/Layout/Layout";
import PortfolioGrid from "../../components/Category/PortfolioGrid";

const PageContainer = styled.div``;

const PortfolioPage = (props) => {
  const { location, data } = props;
  const path = location.pathname;

  const page = data.page;
  const works = data.works

  let sharingImage = false;
  if (page.seo.socialMediaImage) {
    sharingImage = page.seo.socialMediaImage.file.url;
  }

  return (
    <Layout>
      <Seo
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        fullTitle={false}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <IntroBlock heading={page.headingText} />
        <PortfolioGrid works={works.nodes} />
      </PageContainer>
    </Layout>
  );
};

export default PortfolioPage;

export const portfolioQuery = graphql`
  query PortfolioQuery {
    page: contentfulPortfolioPage(slug: { eq: "our-work" }) {
      seo {
        ...seoFragment
      }
      headingText
    }
    works: allContentfulPortfolioEntry(
      sort: { order: DESC, fields: createdAt }
    ) {
      nodes {
        ...portfolioCardFragment
      }
    }
  }
`;
